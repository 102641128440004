import {
  getDocuments,
  getDocumentByDocumentId,
  getDocumentsByTopicId,
  getDocumentsByCompanyId,
  editDocument,
  createDocument,
  deleteDocument,
  getDocumentApplications,
  getDocumentPrograms,
  getDocumentSources,
  createDocumentProgram,
  editDocumentProgram,
  deleteDocumentProgram,
  deleteBulkDocuments,
  editContractDocument,
  getOrigin,
} from '../../modules/admin/document/services/document.service';

const documentModule = {
  state: () => ({
    documents: [],
    document: null,
    documentsFilteredByTopicByCompany: [],
    documentsFilteredByCompany: [],
    documentsFilteredByCountry:[],  // USED FOR LINK DOCUMENTS TO PROGRAM
    documentApplications: [],
    documentPrograms: [],
    documentSources: [],
    allDocumentsForProgram: [], // getDocuments for program
    origin: [],
     

    //DocumentSort
    documentSort: {
      sortField: null,
      sortOrder: null,
      firstPagValue: null,
      rowsPagValue: null,
      documentSourceType: null,
      applications: null,
      countrySearch: null, 
      companyName: null,
    },

    documentSortFromTopicId: {
      sortField: null,
      sortOrder: null,
      firstPagValue: null,
      rowsPagValue: null,
      topicId: null,
      documentSourceType: null,
      applications: null,
      countrySearch: null, 
      companyName: null,
    },

    // TopicDocumentSort
    topicDocumentSort: {
      sortField: null,
      sortOrder: null,
      firstPagValue: null,
      rowsPagValue: null,
      topicId: null,
      documentSourceType: null,
      applications: null,
      countrySearch: null, 
      companyName: null,
    },
    
  }),
  getters: {
    obtainDocuments(state) {
      return state.documents;
    },
    obtainDocument(state) {
      return state.document;
    },
    obtainDocumentFilteredByTopicIdByCompany(state){
      return state.documentsFilteredByTopicByCompany;
    },
    obtainDocumentFilteredByCompany(state){
      return state.documentsFilteredByCompany;
    },
    obtainDocumentFilteredByCountry(state){  // USED FOR LINK DOCUMENTS TO PROGRAM
      return state.documentsFilteredByCountry;
    },
    obtainDocumentApplications(state) {
      return state.documentApplications;
    },
    obtainDocumentPrograms(state) {
      return state.documentPrograms;
    },
    obtainDocumentSources(state) {
      return state.documentSources;
    },
    obtainAllDocumentsForProgram(state) { //getDocuments for AllDocumentForProgram used for edit Programs page 
      return state.allDocumentsForProgram;
    },
    obtainOrigin(state) { // obtain origin getter
      return state.origin;
    },

    //documentSort
    obtainDocumentSort(state) {
      return state.documentSort;
    },
    //documentSort From topic Id
    obtainDocumentSortFromTopicId(state) {
      return state.documentSortFromTopicId
    },
    //TopicDocumentSort
    obtainTopicDocumentSort(state) {
      return state.topicDocumentSort
    },

  },
  mutations: {
    // documents
    setDocuments(state, documents) {
      state.documents = documents;
      // console.log(documents);
      // if(documents.length > 0){
      //   state.documents = documents;
      // }
      // else{
      //   state.documents = [];
      // }
    },
    deleteDocuments(state, documents) {
      documents.forEach(documentId => {
        // console.log(documentId)
        state.documents = [...state.documents.filter((d) => d.documentId !== documentId)]
      });
    },
    // document 
    setDocument(state, document) {
      state.document = document;
    },
    updateDocument(state, document) {
      state.documents = [
        ...state.documents.filter((d) => d.documentId !== document.documentId),
        document,
      ];
    },
    addDocument(state, document) {
      state.documents.push(document);
    },
    deleteDocument(state, documentId) {
      state.documents = [
        ...state.documents.filter((d) => d.documentId !== documentId),
      ];
    },
    // DocumentFiltered By Topic , By companies;
    setDocumentsFilteredByTopicIdByCompany(state, documents){
      state.documentsFilteredByTopicByCompany = documents;
    },

    //  DocumentFiltered By Companies 
    setDocumentsFilteredByCompany(state, documents){
      state.documentsFilteredByCompany = documents;
    },
    deleteDocumentsFilteredByCompany(state,documents){
      documents.forEach(documentId => {
        // console.log(documentId)
        // Check this code we can refactor
        state.documentsFilteredByCompany = [...state.documentsFilteredByCompany.filter((d) => d.documentId !== documentId)]
      });
    },

    setDocumentsFilteredByCountry(state, documents){ // USED FOR LINK DOCUMENTS TO PROGRAM
      state.documentsFilteredByCountry = documents
    },

    // documentApplications
    setDocumentApplications(state, documentApplications) {
      state.documentApplications = documentApplications;
    },
    // documentPrograms 
    setDocumentPrograms(state, documentPrograms) {
      state.documentPrograms = documentPrograms;
    },
    addDocumentProgram(state, documentProgram) {
      state.documentPrograms.push(documentProgram);
    },
    updateDocumentProgram(state, documentProgram) {
      state.documentPrograms = [
        ...state.documentPrograms.filter((d) => d.documentProgramId !== documentProgram.documentProgramId),
        documentProgram,
      ];
    },
    deleteDocumentProgram(state, documentProgramId) {
      state.documentPrograms = [
        ...state.documentPrograms.filter((d) => d.documentProgramId !== documentProgramId),
      ];
    },
    // docunentSources
    setDocumentSources(state, documentSources) {
      state.documentSources = documentSources;
    },
    // AllDocumentsForProgram
    updateAllDocumentsForProgram(state, allDocumentsForProgram) { //setDocuments for AllDocumentForProgram used for edit Programs page 
      state.allDocumentsForProgram = allDocumentsForProgram
    },
    // Origin
    setOrigin(state, origin) {
      state.origin = origin;
    },

    //documentSort
    updateDocumentSort(state, documentSort) {
      state.documentSort = documentSort
    },

    updateDocumentSortFilters(state, documentSortFilter){
      state.documentSort.documentSourceType = documentSortFilter.documentSourceType
      state.documentSort.applications = documentSortFilter.applications
      state.documentSort.countrySearch = documentSortFilter.countrySearch
      state.documentSort.companyName = documentSortFilter.companyName
    },

    //documentSortFromTopicId
    updateDocumentSortFromTopicId(state, documentSortFromTopicId) {
      state.documentSortFromTopicId = documentSortFromTopicId
    },

    updateDocumentSortFromTopicIdFilters(state, documentSortFromTopicIdFilters){
      state.documentSortFromTopicId.documentSourceType = documentSortFromTopicIdFilters.documentSourceType;
      state.documentSortFromTopicId.topicId = documentSortFromTopicIdFilters.topicId;
      state.documentSortFromTopicId.applications = documentSortFromTopicIdFilters.applications;
      state.documentSortFromTopicId.countrySearch = documentSortFromTopicIdFilters.countrySearch;
      state.documentSortFromTopicId.companyName= documentSortFromTopicIdFilters.companyName;
    },

    //topicDocumentSort
    updateTopicDocumentSort(state, topicDocumentSort) {
      state.topicDocumentSort = topicDocumentSort
    },

    updateTopicDocumentSortFilters(state, topicDocumentSortFilter) {//only update Application or documentSourceType
      state.topicDocumentSort.documentSourceType = topicDocumentSortFilter.documentSourceType;
      state.topicDocumentSort.topicId = topicDocumentSortFilter.topicId;
      state.topicDocumentSort.applications = topicDocumentSortFilter.applications;
      state.topicDocumentSort.countrySearch = topicDocumentSortFilter.countrySearch;
      state.topicDocumentSort.companyName= topicDocumentSortFilter.companyName;
    }

  },
  actions: {
    // Documents
    async obtainDocuments({ commit }, { countryId, companyId }) {
      const documents = await getDocuments(countryId, companyId);
      commit('setDocuments', documents);
    },
    async obtainDocumentsByDocumentId({ commit }, documentId) {
      const document = await getDocumentByDocumentId(documentId);
      commit('setDocument', document);
    },
    async obtainDocumentsByTopicId({ commit }, { topicId, programId }) {
      const documents = await getDocumentsByTopicId(topicId, programId);
      commit('setDocuments', documents);
    },

    async obtainDocumentsByTopicIdByCompanyId({ commit },{companyId, topicId}){
      const documents = await getDocumentsByCompanyId(companyId, topicId);
      commit('setDocumentsFilteredByTopicIdByCompany',documents);
    },

    resetDocumentsByTopicIdByCompanyId({commit}){
      commit('setDocumentsFilteredByTopicIdByCompany',[])
    },

    async obtainDocumentsFilteredByCompany({ commit }, { companyId}){
      const documents = await getDocumentsByCompanyId(companyId)
      commit('setDocumentsFilteredByCompany',documents);
    },
    resetDocumentsFilteredByCompany({commit}){
      commit('setDocumentsFilteredByCompany',[])
    },

    // USED FOR LINK DOCUMENTS TO PROGRAM
    // TODO CHANGE TO URL PREPARED FOR RUDY  or look getDocuments(countryId)

    async obtainDocumentsFilteredByCountry({ commit },{countryId}){
      let documents
      if(countryId){
        documents = await getDocuments(countryId, null);
      }
      else{
        documents = await getDocuments();
      }
      commit('setDocumentsFilteredByCountry', documents);
    },

    // TODO DELETE DOCUMENT FILTERED BY COMPANY 

    async addNewDocument({ commit }, document) {
      const response = await createDocument(document);
      commit('addDocument', response.data);
    },
    async modifyDocument({ commit }, document) {
      const documentId = document.get('documentId');
      const response = await editDocument(documentId, document);
      commit('updateDocument', response.data);
    },
    async removeDocument({ commit }, documentId) {
      await deleteDocument(documentId);
      commit('deleteDocument', documentId);
    },
    async removeDocuments({ commit }, documents) {
      await deleteBulkDocuments(documents);
      commit('deleteDocuments', documents)
    },
    // documentApplication
    async obtainDocumentApplications({ commit }) {
      const documentApplications = await getDocumentApplications();
      commit('setDocumentApplications', documentApplications);
    },
    // documentPrograms
    async obtainDocumentPrograms({ commit }) {
      const documentPrograms = await getDocumentPrograms();
      commit('setDocumentPrograms', documentPrograms);
    },
    async addNewDocumentProgram({ commit }, documentProgram) {
      const response = await createDocumentProgram(documentProgram);
      commit('addDocumentProgram', response.data);
    },
    async modifyDocumentProgram({ commit }, documentProgram) {
      const documentProgramId = documentProgram.documentProgramId;
      const response = await editDocumentProgram(documentProgramId, documentProgram);
      commit('updateDocumentProgram', response.data);
    },
    async removeDocumentProgram({ commit }, documentProgramId) {
      await deleteDocumentProgram(documentProgramId);
      commit('deleteDocumentProgram', documentProgramId);
    },
    // DocumentSources
    async obtainDocumentSources({ commit }) {
      const documentSources = await getDocumentSources();
      commit('setDocumentSources', documentSources);
    },

    // allDocumentsForPrograms
    async updateAllDocumentsForProgram({ commit }, allDocumentsForProgram) { //dispatch for AllDocumentForProgram used for edit Programs page 
      commit('updateAllDocumentsForProgram', allDocumentsForProgram);
    },
    // DocumentcontractPerCompany 
    async updateDocumentContractCompany({ commit }, { documentId, contract }) {
      const response = await editContractDocument(documentId, contract)
      commit('updateDocument', response.data);
    },
    //Origin 
    async obtainOrigin({ commit }) {
      const origin = await getOrigin();
      commit('setOrigin', origin);
    },
    

    // documentSortFromTopicId
    updateDocumentSortFromTopicId({ commit }, documentSortFromTopicId) {
      commit('updateDocumentSortFromTopicId', documentSortFromTopicId)
    },

    updateDocumentSortFromTopicIdFilters({commit}, documentSortFromTopicIdFilters){
      commit('updateDocumentSortFromTopicIdFilters',documentSortFromTopicIdFilters);
    },

    //DocumentSort
    updateDocumentSort({ commit }, documentSort) {
      commit('updateDocumentSort', documentSort)
    },

    updateDocumentSortFilters({commit}, documentSortFilters){
      commit('updateDocumentSortFilters',  documentSortFilters)
    },

    //TopicDocumentSort
    updateTopicDocumentSort({ commit }, topicDocumentSort) {
      commit('updateTopicDocumentSort', topicDocumentSort)
    },

    updateTopicDocumentSortFilters({ commit }, topicDocumentSortFilters) { //only update the application or documentType
      commit('updateTopicDocumentSortFilters', topicDocumentSortFilters)
    }


  },
};

export default documentModule;
